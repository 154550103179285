export const homeObjOne = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premium Programming',
  headline: 'Unlimited tools that need zero experience',
  description:
    'Get access to our programming tools that can be used by programmers of any level, also you can get access to plenty tutorials and documentation on different packages',
  buttonLabel: 'Get started',
  imgStart: false,
  img: '/images/svg-1.svg',
  alt: 'programmer at desk',
  dark: true,
  primary: true,
  darkText: false,
  dataAosTxt: 'fade-right',
  dataAosImg: 'fade-left',
  dataAosDuration: '1000',
  dataAosEasing: 'ease-in-out',
  dataAosDelayTxt: '100',
  dataAosDelayImg: '400',
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Discover us',
  headline: 'Your Header here',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer tincidunt, sapien vitae auctor mattis, magna eros sodales massa, a feugiat quam nisi vulputate est. Etiam convallis quam in libero blandit, vitae malesuada ligula tincidunt. Vivamus at dapibus nunc, eu mollis mauris. Donec volutpat risus quis interdum ullamcorper. Fusce lobortis ipsum eget volutpat tincidunt. Fusce id diam massa. ',
  buttonLabel: 'Get started',
  imgStart: true,
  img: '/images/svg-2.svg',
  alt: 'you can be next',
  dark: false,
  primary: false,
  darkText: true,
  dataAosTxt: 'fade-left',
  dataAosImg: 'fade-right',
  dataAosDuration: '1000',
  dataAosEasing: 'ease-in-out',
  dataAosDelayTxt: '100',
  dataAosDelayImg: '400',
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Sign Up',
  headline: 'Your Header here',
  description:
    'Morbi faucibus, augue sit amet accumsan egestas, purus magna fermentum tellus, vel auctor lacus mauris ac enim. Cras elementum sapien eget tellus convallis, tempor condimentum risus vehicula. In magna risus, pretium at posuere dictum, tincidunt vitae libero. Integer dapibus sapien vel erat commodo, ac sodales justo ultricies. Donec non interdum lorem, eget vehicula velit. Aliquam in quam lorem. Nam ut sapien felis. Ut in urna id nisi efficitur gravida. Donec eget tristique metus, nec tincidunt nisi. Nunc auctor venenatis mi, et fringilla libero.',
  buttonLabel: 'Sign Up ',
  imgStart: true,
  img: '/images/svg-3.svg',
  alt: 'lotus image',
  dark: false,
  primary: false,
  darkText: true,
  dataAosTxt: 'fade-left',
  dataAosImg: 'fade-right',
  dataAosDuration: '1000',
  dataAosEasing: 'ease-in-out',
  dataAosDelayTxt: '100',
  dataAosDelayImg: '400',
};
