import React from 'react';
import { animateScroll as scroll } from 'react-scroll';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from 'react-icons/fa';

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialIconsLink,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
} from './FooterElements';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About us</FooterLinkTitle>
              <FooterLink to="/">How it works </FooterLink>
              <FooterLink to="/">Testimonials </FooterLink>
              <FooterLink to="/">Career </FooterLink>
              <FooterLink to="/">Investors </FooterLink>
              <FooterLink to="/">Terms of Service </FooterLink>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to="/">How it works </FooterLink>
              <FooterLink to="/">Testimonials </FooterLink>
              <FooterLink to="/">Career </FooterLink>
              <FooterLink to="/">Investors </FooterLink>
              <FooterLink to="/">Terms of Service </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Videos</FooterLinkTitle>
              <FooterLink to="/">How it works </FooterLink>
              <FooterLink to="/">Testimonials </FooterLink>
              <FooterLink to="/">Career </FooterLink>
              <FooterLink to="/">Investors </FooterLink>
              <FooterLink to="/">Terms of Service </FooterLink>
            </FooterLinkItems>

            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink to="/">How it works </FooterLink>
              <FooterLink to="/">Testimonials </FooterLink>
              <FooterLink to="/">Career </FooterLink>
              <FooterLink to="/">Investors </FooterLink>
              <FooterLink to="/">Terms of Service </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/" onClick={toggleHome}>
              Your Logo
            </SocialLogo>
            <WebsiteRights>
              company name <i className="far fa-copyright"></i>{' '}
              {new Date().getFullYear()} All rights reserved
            </WebsiteRights>
            <SocialIcons>
              <SocialIconsLink href="/" target="_blank" aria-label="Facebook">
                <FaFacebook />
              </SocialIconsLink>
              <SocialIconsLink href="/" target="_blank" aria-label="Instagram">
                <FaInstagram />
              </SocialIconsLink>
              <SocialIconsLink href="/" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconsLink>
              <SocialIconsLink href="/" target="_blank" aria-label="Youtube">
                <FaYoutube />
              </SocialIconsLink>
              <SocialIconsLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconsLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
