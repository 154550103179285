import React, { useEffect } from 'react';
import { Button } from '../ButtonElement';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  Column2,
  ImgWrap,
  Img,
} from './InfoElements';

const InfoSection = ({
  lightBg,
  lightText,
  id,
  imgStart,
  topLine,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dataAosTxt,
  dataAosImg,
  dataAosDuration,
  dataAosEasing,
  dataAosDelayTxt,
  dataAosDelayImg,
}) => {
  useEffect(() => {
    Aos.init({
      disable: window.innerWidth < 480,
    });
  }, []);
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper
                data-aos={dataAosTxt}
                data-aos-duration={dataAosDuration}
                data-aos-easing={dataAosEasing}
                data-aos-delay={dataAosDelayTxt}
              >
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <BtnWrap>
                  <Button
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    to="hero"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img
                  src={img}
                  alt={alt}
                  data-aos={dataAosImg}
                  data-aos-duration={dataAosDuration}
                  data-aos-easing={dataAosEasing}
                  data-aos-delay={dataAosDelayImg}
                />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
