import React from 'react';
import SignIn from '../components/Signin';

const SignInPage = () => {
  return (
    <>
      <SignIn />
    </>
  );
};

export default SignInPage;
