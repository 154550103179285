import React, { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import icon1 from '../../images/svg-4.svg';
import icon2 from '../../images/svg-5.svg';
import icon3 from '../../images/svg-6.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServElements';

const Services = () => {
  useEffect(() => {
    Aos.init({});
  }, []);
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard
          data-aos="fade-right"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
          data-aos-delay="200"
        >
          <ServicesIcon src={icon1} />
          <ServicesH2>Your header here 1</ServicesH2>
          <ServicesP>
            Morbi faucibus, augue sit amet accumsan egestas, purus magna
            fermentum tellus, vel auctor lacus mauris ac enim. Cras elementum
            sapien eget tellus convallis, tempor condimentum risus vehicula. In
            magna risus, pretium at posuere dictum, tincidunt vitae libero.
            Integer dapibus sapien vel erat commodo, ac sodales justo ultricies.
            1
          </ServicesP>
        </ServicesCard>

        <ServicesCard
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
          data-aos-delay="200"
        >
          <ServicesIcon src={icon2} />
          <ServicesH2>Your header here 2</ServicesH2>
          <ServicesP>
            Morbi faucibus, augue sit amet accumsan egestas, purus magna
            fermentum tellus, vel auctor lacus mauris ac enim. Cras elementum
            sapien eget tellus convallis, tempor condimentum risus vehicula. In
            magna risus, pretium at posuere dictum, tincidunt vitae libero.
            Integer dapibus sapien vel erat commodo, ac sodales justo ultricies.
            2
          </ServicesP>
        </ServicesCard>

        <ServicesCard
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
          data-aos-delay="200"
        >
          <ServicesIcon src={icon3} />
          <ServicesH2>Your header here 3</ServicesH2>
          <ServicesP>
            Morbi faucibus, augue sit amet accumsan egestas, purus magna
            fermentum tellus, vel auctor lacus mauris ac enim. Cras elementum
            sapien eget tellus convallis, tempor condimentum risus vehicula. In
            magna risus, pretium at posuere dictum, tincidunt vitae libero.
            Integer dapibus sapien vel erat commodo, ac sodales justo ultricies.
            3
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
